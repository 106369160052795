import { Grid, Paper, Button } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import CB from '../controls/form/CB';
import LangTB from '../controls/form/LangTB';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Redirect } from 'react-router-dom';
import DateTB from '../controls/form/DateTB';
import moment from 'moment';
import WYSIWYG from '../controls/form/Wysiwyg';
import Comm from '../comm/comm';
import { useRecoilState } from 'recoil';
import ToastMessageAtom from '../atoms/ToastMessageAtom';
import Tools from '../tools/tools';


const NewPage = (props) => {
    const [title, setTitle] = useState({ bg: "", en: "" });
    const [active, setActive] = useState(true);
    const [lang, setLang] = useState("bg");
    const [html, setHtml] = useState({ bg: "", en: "" });

    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [back, setBack] = useState(false);
    const [, setSuccess] = useRecoilState(ToastMessageAtom);


    const setHtmlByLang = (val) => {
        html[lang] = val;
        setHtml(JSON.parse(JSON.stringify(html)));
    }

    const langChange = (evt, lng) => {
        setLang(lng || lang);
    }

    const titleChange = (val) => {
        title[lang] = val;
        setTitle({ ...title });
    }

    

    const save = () => {
        Comm.Instance().post("news", {
            "date": "" + moment(date, 'YYYY-MM-DD').toDate().toISOString(),
            "title": title,
            "content": html,
            "active": active,
            "id": props.match.params.newid
        }).then(r => {
            setSuccess({ message: "Данните бяха успешно записани", type: "success" });
            setBack(true)
        })
    }

    const load = () => {
        if (props.match.params.newid) {
            Comm.Instance().get(`news/${props.match.params.newid}`).then(
                r => {
                    console.log("new", r);
                    setTitle(r.data.title);
                    setActive(r.data.active);
                    setDate(moment(r.data.date).format("YYYY-MM-DD"));
                    
                    r.data.content.bg = Tools.fixLinksToFiles(r.data.content.bg);
                    r.data.content.en = Tools.fixLinksToFiles(r.data.content.en);

                    setHtml(r.data.content);
                }
            )
        }

    }


    useEffect(() => load(), []);

    if (back) {
        return <Redirect to="/News"></Redirect>
    }
    return (
        <div className="container">
            <Paper>

                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <Button onClick={() => setBack(true)}><ArrowBackIcon></ArrowBackIcon></Button>
                        <Button onClick={() => save()}><SaveIcon></SaveIcon></Button>
                    </Grid>
                    <Grid item xs={2}>
                        <ToggleButtonGroup
                            value={lang}
                            exclusive
                            onChange={langChange}
                        >
                            <ToggleButton value="bg" aria-label="left aligned">
                                БГ
                            </ToggleButton>
                            <ToggleButton value="en" aria-label="left aligned">
                                EN
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={8}>
                        <LangTB label="Заглавие" value={title[lang]} change={(v) => titleChange(v)}></LangTB>
                    </Grid>
                    <Grid item xs={1}>
                        <CB checked={active} change={(v) => setActive(v)} label="Активна"></CB>
                    </Grid>
                    <Grid item xs={3}>
                        <DateTB value={date} change={(v) => setDate(v)} label="Дата"></DateTB>
                    </Grid>
                    <Grid item xs={12}>
                        <WYSIWYG value={html[lang]} setValue={setHtmlByLang}></WYSIWYG>
                    </Grid>

                </Grid>

            </Paper>
        </div>
    )
}

export default NewPage;