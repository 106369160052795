import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NavMenu from './NavMenu';
import { Grid } from '@material-ui/core';
import SuccessSnackbar from '../controls/form/SnackBar';


const Layout = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };



    return (
        <>
            
            <AppBar position="relative" color={'transparent'}>
                <SuccessSnackbar />
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={2}></Grid>
                    <Grid item xs>
                        <Toolbar className="ml-5">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap>
                                <img src="images/ninkn-logo.jpg" alt="" />
                            </Typography>
                        </Toolbar>

                    </Grid>
                </Grid>

            </AppBar>
            <main>
                <div className="mt-3">
                    {props.children}
                </div>
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <NavMenu handleDrawerClose={handleDrawerClose}></NavMenu>
                </Drawer>


            </main>
            

        </>
    );
}

export default Layout;