import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TB from '../controls/form/TB';
import useStyles from '../styles/styles';
import { Redirect } from 'react-router-dom';
import Comm from '../comm/comm';
import AddCircleIcon from '@material-ui/icons/AddCircle';


const Pages = (props) => {
    const styles = useStyles();
    const [ss, setSS] = useState("");
    const [rows, setRows] = useState([]);
    const [openId, setOpenId] = useState();

    const search = () => {
        Comm.Instance().post(`page/list?text=${ss}`)
            .then(r => {
                
                setRows([...r.data]);
            });

    }



    if (openId) {
        if (openId === -1) {
            return (<Redirect to={`/Page`}></Redirect>)
        } else {
            return (<Redirect to={`/Page/${openId}`}></Redirect>)
        }
    }

    return (
        <div className="container">
            <Paper>
                <Typography variant="h4" gutterBottom>Страници</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={1}>
                        {
                            !props.isModal && <Button onClick={() => setOpenId(-1)}><AddCircleIcon></AddCircleIcon></Button>
                        }

                    </Grid>
                    <Grid item xs={10}>
                        <TB label="Текст за търсене" value={ss} change={(v) => setSS(v)}></TB>
                    </Grid>
                    <Grid item xs={1}>
                        {ss.length >= 3 && <Button onClick={search}><SearchIcon></SearchIcon></Button>}
                    </Grid>
                </Grid>
                {
                    rows.length > 0 && <Paper>
                        <Grid container spacing={3}>
                            <Grid item xs={2} className={styles.gridHeader}>

                            </Grid>
                            <Grid item xs={9} className={styles.gridHeader}>Страница</Grid>
                            <Grid item xs={1} className={styles.gridHeader}>Активна</Grid>
                            {
                                rows.map(d =>
                                    <React.Fragment key={d.id}>
                                        <Grid item xs={2}>
                                            {

                                                !props.isModal && <Button onClick={() => setOpenId(d.id)}><VisibilityIcon></VisibilityIcon></Button>
                                            }
                                            {

                                                props.isModal && <Button onClick={() => props.closeFunc(d.id)}><VisibilityIcon></VisibilityIcon></Button>
                                            }

                                        </Grid>
                                        <Grid item xs={9}>{d.title["bg"]}</Grid>
                                        <Grid item xs={1}>{d.active ? <CheckIcon></CheckIcon> : <HighlightOffIcon></HighlightOffIcon>}</Grid>
                                    </React.Fragment>
                                )
                            }
                        </Grid>

                    </Paper>
                }
            </Paper>
        </div>
    )
}

export default Pages;