import Axios from 'axios';
import keycloak from '../tools/keycloak';

const urls = {
    categories: "json/classification.json"
}

class Comm {

    instance = null;
    instanceForImage = null;
    instanceForNormact = null;

    url = process.env.REACT_APP_API;
    urlFiles = process.env.REACT_APP_FILES;
    urlNormAct = process.env.REACT_APP_NORMACT_API;


    constructor() {
        this.url = process.env.REACT_APP_API;
        this.urlFiles = process.env.REACT_APP_FILES;


        //process.env.NODE_ENV.toLowerCase() === "development" ? "https://localhost:5001/api/" : "http://172.16.0.57:8080/api/";
        //this.url = process.env.NODE_ENV.toLowerCase() === "development" ? "https://www.justice.government.bg/api/" : "http://172.16.0.57:8080/api/";
    }

    Get = (url, params, success) => {
        let keys = [];
        if (params) {
            for (let k in params) {
                keys.push(`${k}=${params[k]}`);
            }
        }

        let href = urls[url] + (keys.length > 0 ? `?${keys.join('&')}` : "");

        this.Instance().get(href).then(resp => success(resp.data));
    }

    Instance(slow = false) {


        if (this.instance === null) {

            this.instance = Axios.create({
                baseURL: this.url,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': "Bearer " + keycloak.token,
                    //'Origin': "http://localhost:3000/"
                },
                timeout: slow ? 60000 : 10 * 60 * 1000
            });


        }
        return this.instance;
    }

    InstanceForImage() {

        if (this.instanceForImage === null) {

            this.instanceForImage = Axios.create({
                baseURL: this.urlFiles,
                headers: { 'Access-Control-Allow-Origin': '*', 'Authorization': "Bearer " + keycloak.token, 'Content-Type': 'multipart/form-data' },
                timeout: 10 * 60 * 1000
            });


        }
        return this.instanceForImage;
    }

    InstanceForNormact() {

        if (this.instanceForImage === null) {

            this.instanceForNormact = Axios.create({
                baseURL: this.urlNormAct,
                headers: { 'Access-Control-Allow-Origin': '*', 'Authorization': "Bearer " + keycloak.idToken },
            });



        }
        return this.instanceForNormact;
    }


}

export default new Comm();