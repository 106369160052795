import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import useStyles from '../styles/styles';
import React, { useEffect, useState } from 'react';
import FakeData from '../fakedata/FakeData';
import TB from '../controls/form/TB';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useRecoilState } from 'recoil';
import ToastMessageAtom from '../atoms/ToastMessageAtom';
import ModalInput from '../controls/ModalInput';
import Comm from '../comm/comm';

const Translation = (props) => {
    const styles = useStyles();
    const [rows, setRows] = useState();
    const [showNewDialog, setShowNewDialog] = useState(false);
    const [, setSuccess] = useRecoilState(ToastMessageAtom);


    useEffect(() => {

        const fetchData = async () => {
            Comm.Instance().get(`labels`).then(
                r => {
                    
                    setRows(r.data);
                    
                }
            )
        };
        fetchData();
    }, []);


    const setTranslation = (key, field, value) => {
        rows[key][field] = value;
        setRows({ ...rows });
    }


    const deleteTranslation = (key) => {
        delete rows[key];
        setRows({ ...rows });
    }

    const save = () => {
        Comm.Instance().post("labels", rows).then(r => setSuccess("Данните бяха успешно записани"));


    }

    const addNew = (v) => {
        if (rows[v.toUpperCase()]) {
            alert("Ключът вече е използван");
        }
        else {
            rows[v.toUpperCase()] = { bg: "", en: "" };
            setRows({ ...rows });
            setShowNewDialog(false);
        }
    }




    if (!rows) {
        return <CircularProgress />
    }

    return (
        <div className="container">
            <Paper>
                <Typography variant="h4" gutterBottom>Преводи</Typography>
                <Button onClick={() => save()}><SaveIcon></SaveIcon></Button>
                <Button onClick={() => setShowNewDialog(true)}><AddCircleIcon></AddCircleIcon></Button>
                <Paper>
                    <Grid container spacing={3}>
                        <Grid item xs={4} className={styles.gridHeader}>Ключ</Grid>
                        <Grid item xs={4} className={styles.gridHeader}>Български</Grid>
                        <Grid item xs={4} className={styles.gridHeader}>Английски</Grid>
                        {
                            Object.keys(rows).map(t =>
                                <React.Fragment key={t}>
                                    <Grid item xs={4} >
                                        <Button onClick={() => deleteTranslation(t)}><DeleteForeverIcon></DeleteForeverIcon></Button>
                                        <Typography variant="overline" gutterBottom>{t}</Typography>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <TB
                                            value={rows[t].bg}
                                            change={(v) => setTranslation(t, "bg", v)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TB
                                            value={rows[t].en}
                                            change={(v) => setTranslation(t, "en", v)}
                                        />
                                    </Grid>

                                </React.Fragment>
                            )
                        }
                    </Grid>
                </Paper>
            </Paper>
            <ModalInput value="" display={showNewDialog} closeFunc={() => setShowNewDialog(false)} saveFunc={(v) => addNew(v)}></ModalInput>

        </div>
    )

}

export default Translation;