import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Redirect } from 'react-router-dom';
import Comm from '../comm/comm';
import WYSIWYG from '../controls/form/Wysiwyg';
import Tools from '../tools/tools';
import { useRecoilState } from 'recoil';
import ToastMessageAtom from '../atoms/ToastMessageAtom';

const MainPage = (props) => {

    const [id, setId] = useState(0);
    const [lang, setLang] = useState("bg");
    const [html, setHtml] = useState({ bg: "", en: "" });
    const [, setSuccess] = useRecoilState(ToastMessageAtom);


    const [back, setBack] = useState(false);

    const setHtmlByLang = (val) => {
        html[lang] = val;
        setHtml(JSON.parse(JSON.stringify(html)));
    }

    const langChange = (evt, lng) => {
        setLang(lng || lang);
    }

    const save = () => {
        Comm.Instance().post("page/main", {
            "title": { bg: "", en: "" },
            "content": html,
            "active": true,
            "id": id
        }).then(r => {
            setSuccess({ message: "Данните бяха успешно записани", type: "success" });
            setBack(true);
        });
    }

    useEffect(() => {
        Comm.Instance().get(`page/main`).then(
            r => {
                setId(r.data.id);
                r.data.content.bg = Tools.fixLinksToFiles(r.data.content.bg);
                r.data.content.en = Tools.fixLinksToFiles(r.data.content.en);
                setHtml(r.data.content);
            }
        )
    }
        , []);


    if (back) {
        return <Redirect to="/Pages"></Redirect>
    }
    return (
        <div className="container">
            <Paper>
                <Typography variant="h4" gutterBottom>Главна страница</Typography>


                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <Button onClick={() => setBack(true)}><ArrowBackIcon></ArrowBackIcon></Button>
                        <Button onClick={() => save()}><SaveIcon></SaveIcon></Button>
                    </Grid>
                    <Grid item xs={2}>
                        <ToggleButtonGroup
                            value={lang}
                            exclusive
                            onChange={langChange}
                        >
                            <ToggleButton value="bg" aria-label="left aligned">
                                БГ
                            </ToggleButton>
                            <ToggleButton value="en" aria-label="left aligned">
                                EN
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <WYSIWYG value={html[lang]} setValue={setHtmlByLang}></WYSIWYG>
                    </Grid>

                </Grid>

            </Paper>
        </div>
    )
}

export default MainPage;