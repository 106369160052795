import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import uuidv4 from 'react-uuid';

const DDL = (props) => {

    const id = uuidv4();
    return (
        <FormControl fullWidth>
            <InputLabel id={id}>{props.label}</InputLabel>
            <Select
                labelId={id}
                value={props.value}
                onChange={(e) => props.change(e.target.value)}                
            >
                <MenuItem value="0">&nbsp;</MenuItem>
                {
                    props.items.map(i => <MenuItem value={i[props.idfield]} key={i[props.idfield]}>{i[props.labelfield]}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}

export default DDL;
