import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import keycloak from '../tools/keycloak';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
const path2Menu = {
    "Translation": ["Translation"],
    "Pages": ["Pages", "Page"],
    "News": ["News", "NewPage"],
    "MainPage": ["MainPage"],
    "Menu": ["Menu"],



};

const NavMenu = (props) => {

    let [uname, setuname] = useState("");
    let location = useLocation();


    let ActiveMenuItem = item => {
        let routes = path2Menu[item];
        let currentRoute = location.pathname.split('/')[1];
        if (!routes || routes.filter(x => x === currentRoute).length === 0)
            return null;
        return "btn btn-primary";
    };

    const logout = () => {
        keycloak.logout();
    }

    keycloak.loadUserInfo().then(r => setuname(r.name));

    return (

        <div className="service-nav">
            <div>
                <IconButton onClick={props.handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <h5> {uname}</h5>
            <Divider />
            <Link to='/Pages' className={ActiveMenuItem("Pages")}>Страници</Link>
            <Link to='/News' className={ActiveMenuItem("News")}>Новини</Link>            
            <Link to='/MainPage' className={ActiveMenuItem("MainPage")}>Главна страница</Link>
            <Link to='/Translation' className={ActiveMenuItem("Translation")}>Преводи</Link>
            <Link to='/Menu' className={ActiveMenuItem("Menu")}>Меню</Link>
            <button className="btn btn-link" onClick={() => logout()}>Изход</button>
        </div>
    );

}

export default NavMenu;
