import moment from 'moment';
import { toast } from 'react-toastify';
import Comm from '../comm/comm';
import keycloak from './keycloak';

class Tools {

    ProcessError = (error) => {
        if (error.response.status === 401) {
            keycloak.logout();
        }
        else {
            toast.error(error.stack);
        }
    }

    ValidateEmail = (mail) => {
        return (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail));
    }

    FormatDate = (date, withTime = true) => {
        var d = new Date(date),
            month = ('' + (d.getMonth() + 1)).padStart(2, '0'),
            day = ('' + d.getDate()).padStart(2, '0'),
            year = ('' + d.getFullYear()).padStart(2, '0'),
            hour = ('' + d.getHours()).padStart(2, '0'),
            mins = ('' + d.getMinutes()).padStart(2, '0');



        if (withTime) {
            return `${day}.${month}.${year} ${hour}:${mins}`;
        }
        else {
            return `${day}.${month}.${year}`;
        }
    }

    NormalizeDate = (date) => {
        if (!date) {
            return null;
        }
        var d = new Date(date),
            month = ('' + (d.getMonth() + 1)).padStart(2, '0'),
            day = ('' + d.getDate()).padStart(2, '0'),
            year = ('' + d.getFullYear()).padStart(2, '0');





        return `${year}-${month}-${day}`;
    }

    ReloadIfOld = (nomen, setFunc) => {
        //localStorage.removeItem("nomen." + nomen);

        const reload = () => Comm.Instance().get("nomenclature?nomen=" + nomen).then(r => {
            localStorage.setItem("nomen." + nomen, JSON.stringify({ onDate: new Date(), value: r.data }));
            setFunc(r.data);
        }
        ).catch(error => Tools.ProcessError(error));
        var old = localStorage.getItem("nomen." + nomen);
        if (!old) {
            reload();
        }
        else {

            var duration = moment.duration(moment().diff(old.onDate));
            var hours = duration.asHours();
            if (hours > 24) {
                reload();
            }
            else {
                setFunc(JSON.parse(old).value);
            }
        }


    }

    GetStringValue = async (nomen, id) => {
        //localStorage.removeItem("nomen." + nomen);

        var result = "";

        const reload = async () => await Comm.Instance().get("nomenclature?nomen=" + nomen).then(r => {
            localStorage.setItem("nomen." + nomen, JSON.stringify({ onDate: new Date(), value: r.data }));
            result = r.data.filter(x => x.key === id)[0].label;
        }
        ).catch(error => Tools.ProcessError(error));
        var old = localStorage.getItem("nomen." + nomen);
        if (!old) {
            await reload();
        }
        else {

            var duration = moment.duration(moment().diff(old.onDate));
            var hours = duration.asHours();
            if (hours > 24) {
                await reload();
            }
            else {
                result = JSON.parse(old).value.filter(x => x.key === id)[0].label;

            }
        }

        return result;


    }

    object2FormData = (obj) => {
        let fd = new FormData();
        for (let k of Object.keys(obj)) {
            fd.append(k, obj[k]);
        }
        return fd;
    }

    fixLinksToFiles(content) {
        console.log("content", content);
        content = (content || "").replaceAll(/="[^"]+?file\?document_id=([0-9]+)"/g, `="${process.env.REACT_APP_FILES}file?document_id=$1"`)


        return content;
    }
}
export default new Tools();