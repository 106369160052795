import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Dialog, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ModalNASearch from '../ModalNASearch';
import Pages from '../../components/Pages';
import Comm from '../../comm/comm';

const WYSIWYG = ({ value, setValue }) => {


    const [innerValue, setInnerValue] = useState(value);
    const [editor, setEditor] = useState();
    const [showModalNA, setShowModalNA] = useState(false);
    const [showModalPage, setShowModalPage] = useState(false);



    const updateValue = (val) => {
        setInnerValue(val);
        setValue(val);
    }


    const getSelectedText = () => {
        var text = "";
        var activeEl = document.activeElement;
        var activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
        if (
            (activeElTagName == "textarea") || (activeElTagName == "input" &&
                /^(?:text|search|password|tel|url)$/i.test(activeEl.type)) &&
            (typeof activeEl.selectionStart == "number")
        ) {
            text = activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd);
        } else if (window.getSelection) {
            text = window.getSelection().toString();
        }
        return text;
    }


    const insertDocument = (id) => {
        editor.editing.view.focus()
        var selContent = getSelectedText();
        if (selContent === "")
            selContent = "link";

        const content = `<a href="${process.env.REACT_APP_FILES}file?document_id=${id}">${selContent}</a>`;

        const viewFragment = editor.data.processor.toView(content);
        const modelFragment = editor.data.toModel(viewFragment);

        editor.model.insertContent(modelFragment);
    }

    const insertImage = (id) => {

        console.log("r", `${process.env.REACT_APP_FILES}file?document_id=${id}`);


        const content = `<img alt="" src="${process.env.REACT_APP_FILES}file?document_id=${id}"/>`;

        const viewFragment = editor.data.processor.toView(content);
        const modelFragment = editor.data.toModel(viewFragment);

        editor.model.insertContent(modelFragment);
    }


    const uploadBlob = (callback) => {
        var x = document.createElement("INPUT");
        x.setAttribute("type", "file");
        x.style.display = 'none';
        document.body.appendChild(x);

        x.addEventListener("change", () => {
            var formData = new FormData();
            formData.append("file", x.files[0]);
            Comm.InstanceForImage().post("file", formData)
                .then(r => {
                    callback(r.data.id);
                })
                .finally(() => {
                    document.body.removeChild(x);
                })



        });


        x.click();


    }


    const PutNormLink = (id) => {
        setShowModalNA(false);
        if (!id) {
            return;
        }
        editor.editing.view.focus();
        var selContent = getSelectedText();
        if (selContent === "")
            selContent = "link";


        const content = `<a href="/NA/${id}">${selContent}</a>`;

        const viewFragment = editor.data.processor.toView(content);
        const modelFragment = editor.data.toModel(viewFragment);

        editor.model.insertContent(modelFragment);
    }



    const putPageLink = (id) => {
        setShowModalPage(false);
        if (!id) {
            return;
        }
        editor.editing.view.focus();
        var selContent = getSelectedText();
        if (selContent === "")
            selContent = "link";


        const content = `<a href="/FreeTextPage/${id}">${selContent}</a>`;

        const viewFragment = editor.data.processor.toView(content);
        const modelFragment = editor.data.toModel(viewFragment);

        editor.model.insertContent(modelFragment);
    }



    useEffect(() => {
        setInnerValue(value);

    }, [value]);





    return (

        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Button onClick={() => setShowModalPage(true)}>Страница</Button>

                <Dialog title="Избор на страница" open={showModalPage} onClose={() => putPageLink()} fullScreen>
                    <Pages isModal={true} closeFunc={putPageLink}></Pages>
                </Dialog>

            </Grid>
            <Grid item xs={3}>
                <Button onClick={() => setShowModalNA(true)}>Нормативен акт</Button>
                <ModalNASearch display={showModalNA} closeFunc={PutNormLink}></ModalNASearch>
            </Grid>
            <Grid item xs={3}>
                <Button onClick={() => uploadBlob(insertDocument)}>Файл</Button>
            </Grid>
            <Grid item xs={3}>
                <Button onClick={() => uploadBlob(insertImage)}>Изображение</Button>
            </Grid>

            <Grid item xs={12}>
                <CKEditor
                    editor={ClassicEditor}
                    config={{ height: '100px' }}
                    onReady={ed => {
                        setEditor(ed);
                    }}
                    data={innerValue}
                    onChange={(event, editor) => {
                        updateValue(editor.getData());
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default WYSIWYG;