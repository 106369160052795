import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TB from '../controls/form/TB';
import useStyles from '../styles/styles';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import Comm from '../comm/comm';
import Tools from '../tools/tools';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const News = (props) => {
    const styles = useStyles();
    const [ss, setSS] = useState("");
    const [rows, setRows] = useState([]);
    const [openId, setOpenId] = useState();

    const search = () => {
        Comm.Instance().post(`news/list?text=${ ss }`).then(r => setRows([...r.data]));

    }


    if (openId) {
        if (openId === -1) {
            return (<Redirect to={`/NewPage`}></Redirect>)
        } else {
            return (<Redirect to={`/NewPage/${openId}`}></Redirect>)
        }
    }

    return (
        <div className="container">
            <Paper>
                <Typography variant="h4" gutterBottom>Новини</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={1}>
                        <Button onClick={() => setOpenId(-1)}><AddCircleIcon></AddCircleIcon></Button>
                    </Grid>
                    <Grid item xs={10}>
                        <TB label="Текст за търсене" value={ss} change={(v) => setSS(v)}></TB>
                    </Grid>
                    <Grid item xs={1}>
                        {ss.length >= 3 && <Button onClick={search}><SearchIcon></SearchIcon></Button>}
                    </Grid>
                </Grid>
                {
                    rows.length > 0 && <Paper>
                        <Grid container spacing={3}>
                            <Grid item xs={2} className={styles.gridHeader}>

                            </Grid>
                            <Grid item xs={7} className={styles.gridHeader}>Новина</Grid>
                            <Grid item xs={2} className={styles.gridHeader}>Дата</Grid>
                            <Grid item xs={1} className={styles.gridHeader}>Активна</Grid>
                            {
                                rows.map(d =>
                                    <React.Fragment key={d.id}>
                                        <Grid item xs={2}>
                                            <Button onClick={() => setOpenId(d.id)}><VisibilityIcon></VisibilityIcon></Button>
                                        </Grid>
                                        <Grid item xs={7}>{d.title["bg"]}</Grid>
                                        <Grid item xs={2}>{moment(d.date, "YYYY-MM-DD").format("DD.MM.YYYY")}</Grid>
                                        <Grid item xs={1}>{d.active ? <CheckIcon></CheckIcon> : <HighlightOffIcon></HighlightOffIcon>}</Grid>
                                    </React.Fragment>
                                )
                            }
                        </Grid>

                    </Paper>
                }
            </Paper>
        </div>
    )
}

export default News;