import React from 'react';
import Layout from './components/Layout';
import { RecoilRoot, } from 'recoil';
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'
import { ThemeProvider } from '@material-ui/core';
import Pages from './components/Pages';
import Translation from './components/Translation';
import News from './components/News';
import WebPage from './components/WebPage';
import NewPage from './components/NewPage';
import MainPage from './components/MainPage';
import MenuDesigner from './components/MenuDesigner';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const App = props => {
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <RecoilRoot>
                <BrowserRouter basename={baseUrl}>
                    <Switch>

                        <Layout>
                            <Route path='/' component={Pages} exact />
                            <Route path='/Pages' component={Pages} exact />
                            <Route path='/Page/:pageid' component={WebPage} exact />
                            <Route path='/Page' component={WebPage} exact />
                            <Route path='/NewPage/:newid' component={NewPage} exact />
                            <Route path='/NewPage' component={NewPage} exact />
                            <Route path='/News' component={News} exact />
                            <Route path='/MainPage' component={MainPage} exact />
                            <Route path='/Menu' component={MenuDesigner} exact />

                            <Route path='/Translation' component={Translation} exact />


                        </Layout>
                    </Switch>
                </BrowserRouter>
            </RecoilRoot>
        </ThemeProvider>
    )



}

export default App;
