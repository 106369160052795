import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MenuItem from '../controls/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import Comm from '../comm/comm';
import ToastMessageAtom from '../atoms/ToastMessageAtom';
import { useRecoilState } from 'recoil';
import AddCircleIcon from '@material-ui/icons/AddCircle';


const MenuDesigner = (props) => {
    const [rows, setRows] = useState([]);
    const [, setSuccess] = useRecoilState(ToastMessageAtom);


    const del = (no) => {
        rows.splice(no, 1);

        setRows([...rows]);
    }

    const swap = (a, b) => {
        var el = { ...rows[a] };
        rows[a] = { ...rows[b] };
        rows[b] = { ...el };
        setRows([...rows]);
    }

    const setValue = (no, prop, v) => {
        rows[no][prop] = v;
        setRows([...rows]);
    }

    const newItem = () => {
        rows.push({
            bg: "Нов елемент",
            en: "New",
            type: 1,
        });
        setRows([...rows]);
    }
    useEffect(() => {
        Comm.Instance().get("menu")
            .then(r => setRows(r.data));
    }, [])

    const save = () => {
        Comm.Instance().post("menu", rows).then(r => setSuccess({ message: "Данните бяха успешно записани", type: "success" })
        );
    }

    return (
        <div className="container">
            <Paper>
                <Typography variant="h4" gutterBottom>Меню</Typography>
                <Button onClick={() => newItem()}><AddCircleIcon></AddCircleIcon></Button>
                <Button onClick={save}><SaveIcon></SaveIcon></Button>
                {
                    <Paper>
                        <Grid container spacing={3}>
                            {
                                rows &&
                                rows.map((i, ix) =>
                                    <MenuItem no={ix} total={rows.length} del={del} swap={swap} setValue={setValue} values={i}></MenuItem>
                                )
                            }
                        </Grid>
                    </Paper>
                }
            </Paper>
        </div>
    )
}

export default MenuDesigner;