import { Button, Dialog, Grid, } from '@material-ui/core';
import React, { useState } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import TB from './form/TB';
import DDL from './form/DDL';
import Pages from '../components/Pages';



const MenuItem = ({ no, total, values, setValue, del, swap }) => {

    const [showModalPage, setShowModalPage] = useState(false)


    const selectPage = (id) => {
        setShowModalPage(false);
        if (!id) {
            return;
        }
        setValue(no, "pageId", id)
    }

    return (
        <React.Fragment key={no}>
        
            <Grid item xs={1}>
                <Button onClick={() => del(no)}><HighlightOffIcon></HighlightOffIcon></Button>
            </Grid>
            <Grid item xs={1}>
                {
                    no > 0 && <Button onClick={() => swap(no, no - 1)}><ArrowUpwardIcon></ArrowUpwardIcon></Button>
                }

            </Grid>
            <Grid item xs={1}>
                {
                    (no < total - 1) && <Button onClick={() => swap(no, no + 1)}><ArrowDownwardIcon></ArrowDownwardIcon></Button>
                }

            </Grid>
            <Grid item xs={3}>
                <TB label="БГ" value={values.bg} change={(v) => setValue(no, "bg", v)}></TB>
            </Grid>
            <Grid item xs={3}>
                <TB label="EN" value={values.en} change={(v) => setValue(no, "en", v)}></TB>
            </Grid>
            <Grid item xs={1}>
                {values.type === 4 && <Button onClick={() => setShowModalPage(true)}><FindInPageIcon></FindInPageIcon></Button>}
                <Dialog title="Избор на страница" open={showModalPage} onClose={() => selectPage()} fullScreen>
                    <Pages isModal={true} closeFunc={selectPage}></Pages>
                </Dialog>
            </Grid>
            <Grid item xs={2}>
                <DDL label="Тип" value={values.type} change={(v) => setValue(no, "type", v)}
                    items={
                        [
                            { id: 1, title: "Главна страница" },
                            { id: 2, title: "Регистър" },
                            { id: 3, title: "Проверка на услуга" },
                            { id: 4, title: "Страница" },
                        ]
                    }
                    idfield="id"
                    labelfield="title"
                ></DDL>
            </Grid>

        </React.Fragment>
    )
}

export default MenuItem;