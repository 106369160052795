import { TextField } from '@material-ui/core';
import React  from 'react';

const DateTB=(props)=>{

    return <TextField label={props.label} variant="outlined" value={props.value} onChange={(e) => props.change(e.target.value)} fullWidth type="date"
        
    />
}

export default DateTB;
