import { Button, Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import TB from './form/TB';

const ModalInput = (props) => {
    let [val, setVal] = useState(props.value);
    return (
        <Dialog title="Въвеждане на стойност" open={props.display} onClose={() => props.closeFunc()}>
            <TB label={props.title} value={val} change={(value) => setVal(value)}></TB>
            <Button onClick={() => props.saveFunc(val)}>Запис</Button>
        </Dialog>

    );
}


export default ModalInput;

