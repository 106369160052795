import React, { useState } from 'react';
import { Grid, Paper, Button, Typography, Dialog } from '@material-ui/core';
import TB from './form/TB';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Comm from '../comm/comm';
import tools from '../tools/tools';



const ModalNASearch = ({ display, closeFunc}) => {
    const step = 20;
    let [showCount, setShowCount] = useState(step);
    let [ss, setss] = useState("");
    let [rows, setRows] = useState(null);




    const Search = () => {
        setRows([]);
        Comm.InstanceForNormact().get("/api/ciela/getdoclist?ss=" + ss)
            .then(res => setRows(res.data))
            .catch(error => tools.ProcessError(error));

    };




    return (
        <Dialog title="Избор на нормативен акт" open={display} onClose={() => closeFunc()} fullScreen>
            <div className="container">
                <h2 className="appeal-title mb-5">Търсене на нормативни актове</h2>
                <Paper>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TB label="Търсене" value={ss} change={setss} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={() => Search()}>
                                Търсене
                        </Button>
                        </Grid>
                    </Grid>
                </Paper>


                {
                    rows &&
                    <>
                        <b>{rows.length} записа</b>
                        <Grid
                            container
                            spacing={3}
                        >
                            {
                                rows.slice(0, Math.min(rows.length, showCount)).map(x =>
                                    <React.Fragment key={x.id}>
                                        <Grid item xs={9}>

                                            <Button color="primary" onClick={() => closeFunc(x.id)}>
                                                <Typography align="left">{x.name}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                )}
                        </Grid>
                        {
                            rows.length > showCount && <Button onClick={() => setShowCount(showCount + step)}><MoreHorizIcon></MoreHorizIcon></Button>
                        }
                    </>
                }
            </div>
        </Dialog>
    );
}


export default ModalNASearch;